import { axiosApiInstance } from "@/plugins/axios";
function get_sending_charge(amount, ac_number, method) {

  let sending_charge = {};
  sending_charge['bank']= () => {
      let charges = this.sending.filter(
        (charge) =>
          charge.min <= amount && amount <= charge.max && charge.mode === "bank"
      );
      let charge = charges.length > 0 ? charges[0].charge : 0;

      return charge;
    }
    sending_charge[this.money]= () => {
      let charges = this.sending.filter(
        (charge) =>
          charge.min <= amount &&
          amount <= charge.max &&
          charge.mode === 'mobile'
      );
      let charge = charges.length > 0 ? charges[0].charge : 0;

      return charge;
    }
  
  return sending_charge[method]?
  sending_charge[method]():0;
}

function get_withdrawal_charge(amount, ac_number, method) {
  let get_network = (contact) => {
    let networks = {
      "75": "airtel",
      "70": "airtel",
      "74": "airtel",
      "77": "mtn",
      "78": "mtn",
      "39": "mtn",
      "31": "mtn",
    };
    contact = Number(contact).toString();
    const pretext = contact.startsWith("256")
      ? contact.substring(3, 5)
      : contact.substring(0, 2);

    return networks[pretext];
  };

  let withdrawal = {}
  withdrawal['bank']= () => {
      let charges = this.withdrawal.filter(
        (charge) =>
          charge.min <= amount && amount <= charge.max && charge.mode === "bank"
      );

      const charge = charges.length > 0 ? charges[0].charge : 0;

      return charge;
    }
    withdrawal[this.money]= () => {
      let fees = this.withdrawal.filter(
        (charge) =>
          charge.min <= Number(amount) &&
          Number(amount) <= charge.max &&
          charge.mode === 'mobile' &&
          charge.network === get_network(ac_number)
      );

      const fee = fees.length > 0 ? fees[0].charge : 0;
      return fee;
    }
  
  return withdrawal[method]?withdrawal[method]():0;
}
function get_tax_charge(amount, ac_number, method) {
  let tax_charge = {}
  tax_charge['bank']= () => {
      let charges = this.tax.filter(
        (charge) =>
          charge.min <= amount && amount <= charge.max && charge.mode === "bank"
      );
      const charge = charges.length > 0 ? charges[0].charge : 0;
      const tax = (charge / 100) * amount;
      return tax;
    }
   tax_charge[this.money]= () => {
      let charges = this.tax.filter(
        (charge) =>
          charge.min <= amount &&
          amount <= charge.max &&
          charge.mode === 'mobile'
      );
      const charge = charges.length > 0 ? charges[0].charge : 0;
      const tax = (charge / 100) * amount;
      return tax;
    }
  return tax_charge[method]?tax_charge[method]():0;
}

function get_charges() {
  this.loading = true;

  axiosApiInstance({
    url:this.url+"/payments/charges/",
    method: "get",
  })
    .then((res) => {
      this.withdrawal = res.data.data.withdrawal;
      this.sending = res.data.data.sending;
      this.tax = res.data.data.tax;

      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
      this.errorMessage = "No Charges.";
    });
  // axios.get
}

function total_amount(list, field='payment') {
  return list.reduce((a, b) => a + (Number(b[field]) || 0), 0);
}
function total_tax(list,field="payment") {
  return list.reduce(
    (a, b) =>
      a +
      (this.get_tax_charge(Number(b[field]), b[this.account], b["method"]) ||
        0),
    0
  );
}
function total_sending(list,field='payment') {
  return list.reduce(
    (a, b) =>
      a +
      (this.get_sending_charge(
        Number(b[field]),
        b[this.account],
        b["method"]
      ) || 0),
    0
  );
}
function total_withdrawal(list,field='payment') {
  return list.reduce(
    (a, b) =>
      a +
      (this.get_withdrawal_charge(
        Number(b[field]),
        b[this.account],
        b["method"]
      ) || 0),
    0
  );
}
function total(list) {
  let total_withdrawal = this.include_withdrawal
    ? this.total_withdrawal(list)
    : 0;
  let total_tax = this.include_tax ? this.total_tax(list) : 0;
  let total_sending = this.total_sending(list);
  let amount = this.total_amount(list);
  let total = total_sending + total_withdrawal + total_tax + amount;
  return total.toLocaleString();
}

export {
  get_tax_charge,
  get_withdrawal_charge,
  get_sending_charge,
  get_charges,
  total,
  total_withdrawal,
  total_amount,
  total_sending,
  total_tax,
};
