import { axiosApiInstance } from "@/plugins/axios";
import {
  get_charges,
  get_tax_charge,
  get_withdrawal_charge,
  get_sending_charge,
  total,
  total_withdrawal,
  total_amount,
  total_sending,
  total_tax,
} from "@/modules/payments/utils";
function add_payment_schedule() {
  // const data = this.form;
  let data = {};
  this.selected.forEach((item) => {
    data[item.farmer_id] = item["payment"];
  });
  this.loading = true;
  axiosApiInstance({
    url:this.url+"/payments/schedules/",
    method: "post",
    data: JSON.stringify({
      farmers: data,
      include_tax: this.include_tax,
      include_withdrawal: this.include_withdrawal,
    }),
  })
    .then((res) => {
      console.log(res.status);
      // this.loading = false;
      this.message = "Successfully Created Schedule!!";
      this.success = true;
      this.$vToastify.success(this.message);
      this.$emit('Successful')
      this.$bvModal.hide("add-schedule");
      setTimeout(() => {
        this.success = false;
        this.loading = false;
        this.verified = false;

        if (!this.currentRoute.includes('/payments/schedules')){
          this.$router.push("/payments/schedules");
        }
        

      },2000);
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
      this.message = "Failed to Create Schedule!";
      this.$vToastify.error(this.message);
      this.success = false;
    });
}
function get_produce_pending_payments() {
  this.loading = true;

  axiosApiInstance({
    url:this.url+"/payments/",
    method: "get",
  })
    .then((res) => {
      this.users = res.data.data;
      this.success = false;
      this.message =
        this.users.length == 0 ? "Bulk produce when you get a chance" : "";

      // console.log(res.status);

      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
      this.errorMessage = "No Produce Loaded.";
    });
  // axios.get
}

function on_row_selected(items) {
  this.selected = items;
}
function select_all_rows() {
  this.users.forEach((item, index) => {
    let check = item.payment > 0 && item.ac_number ? true : false;
    this.select_row(check, index);
  });
}

function clear_selected() {
  this.$refs.addScheduleTable.clearSelected();
}
function toggle_all(checked) {
  checked ? this.select_all_rows() : this.clear_selected();
}
function select_row(checked, index) {
  checked
    ? this.$refs.addScheduleTable.selectRow(index)
    : this.$refs.addScheduleTable.unselectRow(index);
}

export {
  add_payment_schedule,
  on_row_selected,
  select_all_rows,
  clear_selected,
  get_produce_pending_payments,
  toggle_all,
  get_sending_charge,
  get_withdrawal_charge,
  get_tax_charge,
  select_row,
  get_charges,
  total,
  total_withdrawal,
  total_amount,
  total_sending,
  total_tax,
};
