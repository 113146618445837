function get_user_label(usertype) {
  let type = usertype.toLowerCase();
  switch (type) {
    case "farmer":
      return "FA";
    case "agent":
      return "AG";
    case "farmer_grup":
      return "FG";
    default:
      return "FA";
  }
}
function get_user_class(usertype) {
  let type = usertype.toLowerCase();
  switch (type) {
    case "farmer":
      return "success";
    case "agent":
      return "primary";
    case "farmer_grup":
      return "warning";
    default:
      return "success";
  }
}

export { get_user_class, get_user_label };
