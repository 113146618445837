import { axiosApiInstance } from "@/plugins/axios";
import {
  get_charges,
  get_tax_charge,
  get_withdrawal_charge,
  get_sending_charge,
  total,
  total_withdrawal,
  total_amount,
  total_sending,
  total_tax,
} from "@/modules/payments/utils";
import moment from "moment";
function get_schedule_data() {
  this.loading = true;
  let schedule_id = this.$route.params.schedule_id;
  axiosApiInstance({
    url:this.url+"/payments/schedules/" +
      schedule_id,
    method: "get",
  })
    .then((res) => {
      this.schedule_data = res.data.data[0];
      this.schedule= this.schedule_data['schedule'];
      this.include_withdrawal= this.schedule.include_withdrawal
      this.include_tax = this.schedule.include_tax

      this.items = this.schedule_data['schedule_data']
      

      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
    });
  // axios.get
}

function format_date(date) {
  return date ? moment(date,"YYYY-MM-DD HH:mm:ss").format("LL") : "-";
}
export { get_schedule_data, format_date,
  get_charges,
  get_tax_charge,
  get_withdrawal_charge,
  get_sending_charge,
  total,
  total_withdrawal,
  total_amount,
  total_sending,
  total_tax,
};
